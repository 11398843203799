import { Injectable } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class CoreTeamService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private apiservice: ApiService) {}

  emitSearchQuery(arg: String) {
    this.searchQuery.next(arg);
  }
  createCoreTeam(formData: any): Observable<any> {
    let path = `${this.baseUrl}/core-team`;
    return this.apiservice.post(path, formData);
  }

  getCoreTeamById(id: string): Observable<any> {
    const path = `${this.baseUrl}/core-team/${id}`;
    return this.apiservice.get(path);
  }

  updateCoreTeam(coreTeamData: any): Observable<any> {
    const url = `${this.baseUrl}/core-team`;
    return this.apiservice.put(url, coreTeamData).pipe(
      catchError((error: any) => {
        console.error('Error updating Core Team:', error);
        throw error;
      })
    );
  }

  updateCoreTeamStatus(coreTeamData: {
    core_teamID: string;
    status: string;
    offBoardDate: string;
  }): Observable<any> {
    const url = `${this.baseUrl}/core-team/status`;
    return this.apiservice.put(url, coreTeamData).pipe(
      catchError((error: any) => {
        console.error('Error updating Core Team Status:', error);
        throw error;
      })
    );
  }

  getCoreTeamByAccountId(
    accountId: string,
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    status?: string
  ): Observable<any> {
    let path = `${this.baseUrl}/core-team/list?accountId=${accountId}&page=${pageNumber}&limit=${limit}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}`;

    if (status) {
      path += `&status=${status}`;
    }

    return this.apiservice.get(path);
  }

  deleteCoreTeam(coreTeamId: string): Observable<any> {
    const path = `${this.baseUrl}/core-team/${coreTeamId}`;
    return this.apiservice.delete(path);
  }

  getResourceList(): Observable<any> {
    const path = `${this.baseUrl}/master/resources`;
    return this.apiservice.get(path);
  }

  getCoreTeamByCostCenterId(
    pageNumber: number,
    limit: number,
    search: string,
    sortBy: string,
    orderBy: string,
    cost_centerID: string
  ): Observable<any> {
    const path = `${this.baseUrl}/core-team/list?cost_centerID=${cost_centerID}&page=${pageNumber}&limit=${limit}&sortBy=${sortBy}&orderBy=${orderBy}&search=${search}`;
    return this.apiservice.get(path);
  }
}
